a,
a:visited {
  color: white;
}

.App {
  background-color: #282c34;
  min-height: 100svh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opactiy: 1;
  }
}

.App-header {
  animation: shrinkToSize 2s;
}

@keyframes shrinkToSize {
  from {
    font-size: 25px;
  }
}

.profile-pic {
  width: 200px;
  border-radius: 50%;
}

.job-title-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.job-title {
  margin: 0 3vw 0 3vw;
}

.chevron {
  margin-top: 8px;
  cursor: pointer;
  animation: fadeIn 4s ease;
}

.chevron:hover {
  opacity: 70%;
}

.contacts {
  font-size: calc(10px + 2vmin);
  display: flex;
  align-items: center;
  height: 70px;
  animation: fadeIn 4s;
}

.github-logo,
.github-logo:active,
.instagram-logo,
.instagram-logo:active {
  width: 50px;
  margin-right: 10px;
}

.github-logo:hover,
.instagram-logo:hover {
  width: 60px;
  transition: width 200ms ease;
}

.linkedin-logo,
.linkedin-logo:active {
  width: 60px;
  margin-left: 10px;
}

.linkedin-logo:hover {
  width: 70px;
  transition: width 200ms ease;
}

@media only screen and (min-width: 400px) {
  .App-header {
    animation: shrinkToSizeTablet 2s;
  }
}

@keyframes shrinkToSizeTablet {
  from {
    font-size: 30px;
  }
}

@media only screen and (min-width: 1280px) {
  .App-header {
    animation: shrinkToSizeDesktop 2s;
  }
}

@keyframes shrinkToSizeDesktop {
  from {
    font-size: 100px;
  }
}
